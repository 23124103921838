<template>
  <v-row
    no-gutters
    class="h-100"
  >
    <v-col cols="12" offset="0" md="4" offset-md="4" class="d-flex flex-column justify-center align-center">
      <div class="mb-12">
        <v-img
          eager
          :transition="false"
          :src="require('@/assets/logo/SPEDIMO_logo.svg')"
          width="250px"
          height="35px"
          class="ma-1"
        ></v-img>
      </div>
      <v-card class="w-100" flat outlined>
        <v-card-title>
          <h4 class="primary--text">
            {{ $t('forgottenPassword.title') }}
          </h4>
        </v-card-title>
        <v-card-text class="px-8">
          <div class="mb-5">
            {{ $t('forgottenPassword.info') }}
          </div>
          <v-form
            ref="loginForm"
            v-model="valid"
            onSubmit="return false;"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="form.email"
                  :label="$t('forgottenPassword.email')"
                  outlined
                  dense
                  single-line
                  required
                  validate-on-blur
                  :rules="emailRules"
                  @keyup.enter="submitHandle"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions class="px-0">
            <v-btn color="primary" outlined @click="$router.push({ name: 'Login' })">
              {{ $t('forgottenPassword.cancel') }}
            </v-btn>
            <v-btn color="primary" class="ml-auto" :disabled="submitLoading" :loading="submitLoading" @click="submitHandle" depressed>
              {{ $t('forgottenPassword.submit') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import services from '@/services'

export default {
  name: 'ForgottenPassword',
  data () {
    return {
      form: {
        email: ''
      },
      submitLoading: false,
      valid: true,
      emailRules: [
        v => !!v.trim() || this.$t('validation.emailRequired'),
        v => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) || this.$t('validation.emailInvalidFormat')
      ]
    }
  },
  methods: {
    async submitHandle () {
      await this.$refs.loginForm.validate()
      if (!this.valid) {
        return
      }
      this.submitLoading = true
      try {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('ResetPassword')
        const { data } = await services.post('Authentication/ResetPassword', {
          captchaToken: token,
          email: this.form.email
        })
        sessionStorage.setItem('expiryDate', data.expiryDate)
        sessionStorage.setItem('email', this.form.email)
        await this.$router.push({ name: 'ResetPasswordCodeVerification' })
      } catch (e) {}
      this.submitLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
